import { useState, useContext, useEffect } from "react";
import { ArrowBackIos } from "@material-ui/icons";
import {
  Grid,
  TextField,
  Button,
  Checkbox,
  IconButton,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { UserContext } from "../../context/UserContext";
import StandardsService from "../../services/StandardsService";
import Select from "react-select";
import { TITLE_LENGTH_LIMITATION } from "../../utils/fieldValidation";
import { useParams } from "react-router-dom";
import {
  allStandards,
  CodeCreationNote,
  customStylesForCodeCreation,
} from "../../utils/utils";
import HttpService from "../../services/HttpService";
import LoaderComponent from "../loader";
import {
  checkExistingCode,
  regexForPlusMinusAtEnd,
  validateAndFormatCodes,
} from "../../utils/regexUtils";
import AlertDialog from "../generic/InternalSimAlertDialog";

const initialState = {
  codeName: "",
  codeStandards: [],
  isSimFeedback: true,
  isSimVariable: true,
};

const CreateOrUpdateCode = (props) => {
  const { token } = useContext(UserContext);
  const history = useHistory();

  const [formState, setFormState] = useState({ ...initialState });
  const [standards, setStandards] = useState();
  const { codeId } = useParams();
  const [isEdit, setIsEdit] = useState(
    !codeId
      ? { title: "Add", submitButtonTitle: "Create" }
      : { codeId: codeId, title: "Edit", submitButtonTitle: "Update" }
  );
  const [loading, setLoading] = useState(false);
  const [codeNameState, setCodeNameState] = useState({
    hasError: false,
    errorMessage: "",
  });
  const [codesData, setCodesData] = useState([]);
  const [codeDependency, setCodeDependency] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const backToCodesList = () => {
    history.push("/codes");
  };

  useEffect(() => {
    loadStandardsAndData();
  }, [props]);

  const loadStandardsAndData = async () => {
    try {
      setLoading(codeId ? true : false);
      // Fetch standards
      const standardsFromBackEnd =
        await StandardsService().getAllPortalStandards(token);
      const standards = standardsFromBackEnd?.data?.standards.map(
        (standard) => ({
          ...standard,
          value: standard.uuid,
        })
      );
      setStandards(standards);

      HttpService()
        .getRequest(token, "/codes")
        .then((response) => {
          setCodesData(response.data);
          setLoading(false);
        })
        .catch((e) => {
          console.debug("Error occurred while getting the codes", e.message);
        });

      // Fetch code data if editing
      if (codeId) {
        const codeResponse = await HttpService().getRequestById(
          token,
          "/codes/",
          codeId
        );
        const response = await HttpService().getRequestById(
          token,
          "/codes/getAllSimForCode/",
          codeId
        );
        setLoading(false);
        setCodeDependency(response?.data);

        // Map standards to include labels
        const updatedStandards = codeResponse?.data.codeStandards.map(
          (standard) => {
            const matchedStandard = standards.find(
              (item) => item.value === standard.uuid
            );
            return {
              ...standard,
              label: matchedStandard?.label,
              value: standard.uuid,
            };
          }
        );

        setFormState({
          ...codeResponse?.data,
          codeStandards: updatedStandards,
        });
      }
    } catch (error) {
      console.error("Error loading data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleStandardsChnage = (selectedStandards) => {
    if (
      selectedStandards &&
      selectedStandards.some((option) => option.value === "*")
    ) {
      setFormState({
        ...formState,
        codeStandards: standards,
      });
    } else if (selectedStandards?.length) {
      setFormState({
        ...formState,
        codeStandards: selectedStandards,
      });
    } else {
      setFormState({
        ...formState,
        codeStandards: [],
      });
    }
  };

  const getStandardsOptions = () => {
    if (standards != [] && standards != undefined) {
      if (formState.codeStandards?.length == standards?.length) {
        return [...formState.codeStandards];
      } else {
        return [allStandards, ...standards];
      }
    }
  };

  const handleCheckboxChange = async (event) => {
    const { name, checked } = event.target;
    if (name === "useInAccreditationFreeTextResponseScoring") {
      setFormState({
        ...formState,
        isSimFeedback: checked,
      });
    } else if (name === "useInSimVariable") {
      if (codeDependency?.length > 0) {
        setOpenDeleteModal(true);
      } else {
        setFormState({
          ...formState,
          isSimVariable: checked,
        });
      }
    }
  };

  const handleSubmit = () => {
    // Trimming leading and trailing spaces
    let trimmedCodeName = formState.codeName.trim();

    // If the codeName is readonly (edit mode), don't check for duplicates
    if (codeId && !formState.codeName) {
      setCodeNameState({
        hasError: false,
        errorMessage: "",
      });
    } else {
      if (!trimmedCodeName) {
        setCodeNameState({
          hasError: true,
          errorMessage: "Code Name is required.",
        });
        setLoading(false);
        return;
      }

      // Check if the code name already exists and is active, only in create mode
      if (!codeId) {
        const existingCode = checkExistingCode(trimmedCodeName, codesData);
        if (existingCode) {
          setCodeNameState({
            hasError: true,
            errorMessage:
              "Code Name already exists, please choose another one.",
          });
          setLoading(false);
          return;
        }
      }

      trimmedCodeName = validateAndFormatCodes(trimmedCodeName);
      if (!trimmedCodeName) {
        setCodeNameState({
          hasError: true,
          errorMessage:
            "Code Name is not valid, please follow the above instructions to create code.",
        });
        setLoading(false);
        return;
      }
    }

    // Update the form state with the adjusted code name
    setFormState((prevState) => ({
      ...prevState,
      codeName: trimmedCodeName,
    }));

    // Clear errors if validation passes
    setCodeNameState({
      hasError: false,
      errorMessage: "",
    });

    setLoading(true);
    HttpService()
      .postRequestWithAuthentication(
        { ...formState, codeName: trimmedCodeName },
        "/codes/saveOrUpdate",
        token
      )
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          backToCodesList();
          console.debug("Code saved successfully.");
        } else {
          setLoading(false);
          console.debug("Error occurred while creating code.");
        }
      })
      .catch((e) => {
        setLoading(false);
        console.error("Error while saving code", e);
      });
  };

  const handleCodeNameChange = (event) => {
    const { value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      codeName: value,
    }));

    // Resetting error states if the input is valid
    if (value.trim()) {
      setCodeNameState({
        hasError: false,
        errorMessage: "",
      });

      //  Reset error if the input ends with '+' or '-'
      if (regexForPlusMinusAtEnd.test(value.trim())) {
        setCodeNameState({
          hasError: false,
          errorMessage: "",
        });
      }
    }
  };

  return (
    <div className="card">
      <div className="card-header color-blue">
        <h3 className="h4">
          <IconButton onClick={backToCodesList}>
            <ArrowBackIos fontSize="small" />
          </IconButton>
          {`${isEdit.title} Code`}
        </h3>
      </div>
      <div className="card-body">
        <div className="overflow-auto">
          <div className="simBuilderForm">
            <Grid container spacing={2} alignItems="center">
              <p className="text-error">
                <b>Note: </b>
                <CodeCreationNote />
              </p>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={3}>
                <Typography className="color-blue required">
                  Code Name
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  error={codeNameState.hasError}
                  variant="outlined"
                  id="codeName"
                  name="codeName"
                  label="Code Name"
                  value={formState.codeName}
                  onChange={handleCodeNameChange}
                  required
                  fullWidth
                  inputProps={{ maxLength: TITLE_LENGTH_LIMITATION }}
                  helperText={codeNameState.errorMessage}
                  InputProps={{
                    readOnly: !!codeId,
                    style: codeId ? { backgroundColor: "#f0f0f0" } : {},
                  }}
                  FormHelperTextProps={{
                    style: { marginLeft: 0 },
                  }}
                  style={{ margin: 0 }}
                  className="error"
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              alignItems="center"
              style={{ marginTop: 12 }}
            >
              <Grid item xs={3}>
                <Typography className="color-blue">Add Standard</Typography>
              </Grid>
              <Grid item xs={9}>
                <div style={{ marginTop: 8, width: "100%" }}>
                  <Select
                    isMulti
                    value={formState.codeStandards}
                    onChange={handleStandardsChnage}
                    options={getStandardsOptions()}
                    placeholder="Standards"
                    styles={customStylesForCodeCreation}
                    className="w-100"
                    menuPortalTarget={document.body}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              alignItems="center"
              style={{ marginTop: 16 }}
            >
              <Grid item xs={3}></Grid>
              <Grid item xs={9}>
                <div className="checkbox-container">
                  <div className="checkbox-group">
                    <Checkbox
                      checked={formState.isSimFeedback}
                      onChange={handleCheckboxChange}
                      name="useInAccreditationFreeTextResponseScoring"
                      color="primary"
                    />
                    <Typography
                      onClick={(e) => e.stopPropagation()}
                      className="checkbox-label"
                    >
                      Use in Accreditation Free Text Response Scoring
                    </Typography>
                  </div>
                  <div className="checkbox-group">
                    <Checkbox
                      checked={formState.isSimVariable}
                      onChange={handleCheckboxChange}
                      name="useInSimVariable"
                      color="primary"
                    />
                    <Typography
                      onClick={(e) => e.stopPropagation()}
                      className="checkbox-label"
                    >
                      Use in Sim Variable
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              alignItems="center"
              style={{ marginTop: 16 }}
            >
              <Grid item xs={3}></Grid>
              <Grid
                item
                xs={9}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={backToCodesList}
                  className="justify-content-center"
                  name="cancel"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  {isEdit.submitButtonTitle}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      {loading && (
        <div className="overlay">
          <LoaderComponent />
        </div>
      )}
      {openDeleteModal && (
        <AlertDialog
          open={openDeleteModal}
          handleNo={() => setOpenDeleteModal(false)}
          message={
            codeDependency && (
              <p className="dialog-content-font">
                The code{" "}
                <span className="MuiButton-textSecondary font-weight">
                  {formState?.codeName}
                </span>
                <span>
                  {" "}
                  in currently use by the following simulations:{" "}
                  <span className="response-grey">
                    {codeDependency
                      ?.map((item, index) => (
                        <strong key={index}>{item.trim()}</strong>
                      ))
                      .reduce((prev, curr) => [prev, ", ", curr])}
                  </span>
                </span>
                <br />
                <span className="MuiButton-textSecondary font-weight">
                  If you want to disable this, you need to disable the
                  "Accreditation type" checkbox in variable section from the
                  mentioned simulations.
                </span>
              </p>
            )
          }
          showDialogActions={false}
        />
      )}
    </div>
  );
};

export default CreateOrUpdateCode;
