import {
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Select,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useEffect, useState } from "react";
import { fontFamily, isNullOrEmpty } from "../../utils/utils";
import formLabelsTheme from "../generic/FormLabelsThem";
import { MuiThemeProvider } from "@material-ui/core";
import { UserContext } from "../../context/UserContext";
import { useContext } from "react";
import HttpService from "../../services/HttpService";
import { Checkbox } from "@material-ui/core";
import ReactSelect from "react-select";

// React select styles
const reactSelectStyles = {
  menuList: (provided) => ({
    ...provided,
    fontFamily,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    fontFamily,
  }),
  control: (provided) => ({
    ...provided,
    position: "relative",
    height: "52px",
    fontFamily,
    overflow: "hidden",
    boxSizing: "border-box",
    maxWidth: "100%",
  }),
  singleValue: (provided) => ({
    ...provided,
    whiteSpace: "pre",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "calc(100% - 8px)",
    boxSizing: "border-box",
  }),
  input: (provided) => ({
    ...provided,
    whiteSpace: "pre",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    boxSizing: "border-box",
  }),
};

const initialLocalState = {
  id: null,
  name: "",
  displayName: "",
  desc: "",
  type: "",
  variableContext: false,
  startVal: "",
  showOnReport: false,
  useAvg: false,
  minRange: "",
  maxRange: "",
  step: "",
  sliderLabels: null,
  simId: null,
  codeId: null,
  isAccreditationType: false,
};

const EditVariable = (props) => {
  const [localState, setLocalState] = useState({ ...initialLocalState });
  const [sliderLabelValue, setSliderLabelValue] = useState("");
  const [sliderLabelText, setSliderLabelText] = useState("");
  const [varErrorFlag, setVarErrorFlag] = useState(false);
  const [codesData, setCodesData] = useState([]);
  const [selectError, setSelectError] = useState(false);

  const { token } = useContext(UserContext);

  useEffect(() => {
    if (props.isAddNewVariable) {
      setLocalState({ ...initialLocalState });
      props.setIsAddNewVariable(false);
    }
  }, [props.isAddNewVariable]);

  useEffect(() => {
    HttpService()
      .getRequest(token, "/codes/getAllCodes")
      .then((response) => {
        console.debug("Codes retrieved successfully");
        setCodesData(response.data);
      })
      .catch((e) => {
        console.debug("Error occurred while getting the codes", e.message);
      });

    if (!!props.val) {
      setSelectError(false);
      setLocalState({
        ...localState,
        ...props.val,
      });
    } else {
      setLocalState(initialLocalState);
    }
  }, [props]);

  useEffect(() => {
    if (localState.isAccreditationType) {
      const accreditationCode = codesData.find(
        (code) => code?.id == localState.codeId
      );
      setLocalState((prevState) => {
        return {
          ...prevState,
          name: accreditationCode?.codeName ? accreditationCode.codeName : "",
          codeId: accreditationCode?.id,
        };
      });
    }
  }, [localState.isAccreditationType]);

  const handleChange = (e) => {
    const name = e.target.name;
    setLocalState({ ...localState, [name]: e.target.value });
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setLocalState({
      ...localState,
      [name]: value,
    });
  };

  const handleCheckChanged = (e) => {
    const { name, checked } = e.target;
    setLocalState({ ...localState, [name]: checked });
    if (name == "isAccreditationType" && !checked) {
      setSelectError(false);
    }
  };

  const handleSave = () => {
    if (localState.isAccreditationType && !localState.codeId) {
      setSelectError(true);
      return;
    }
    if (localState.type !== "number") {
      localState.variableContext = false;
    }
    if (localState.isAccreditationType) {
      localState.type = "number";
    } else {
      localState.codeId = null;
    }
    props.handleSave(localState);
  };

  const getSliderLabels = () => {
    if (localState.sliderLabels !== null) {
      return localState.sliderLabels.map((l, i) => {
        return (
          <p className="listItem" key={i}>
            <span>
              {l.label} @ {l.value}
            </span>
            <IconButton
              onClick={() => props.deleteSliderLabel(localState, l.id, i)}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </p>
        );
      });
    }
  };

  const addSliderLabel = () => {
    if (!isNullOrEmpty(sliderLabelValue) && !isNullOrEmpty(sliderLabelText)) {
      const newSliderLabel = {
        value: sliderLabelValue,
        label: sliderLabelText,
      };
      const sliderLabels = localState.sliderLabels
        ? localState.sliderLabels
        : [];
      setLocalState({
        ...localState,
        sliderLabels: sliderLabels.concat(newSliderLabel),
      });

      setSliderLabelText("");
      setSliderLabelValue("");
      setVarErrorFlag(false);
    } else {
      setVarErrorFlag(true);
    }
  };

  const handleDropdownChange = (selectedOption) => {
    setSelectError(false);
    if (selectedOption) {
      setLocalState({
        ...localState,
        name: selectedOption.label,
        codeId: selectedOption.value,
      });
    } else {
      setLocalState({
        ...localState,
        name: "",
        codeId: null,
      });
    }
  };

  const getDropdownOptions = () => {
    return codesData.map((code) => ({
      value: code.id,
      label: code.codeName,
    }));
  };

  return (
    <div className="variable_edit_form">
      <FormControlLabel
        control={
          <Checkbox
            id="isAccreditationType"
            name="isAccreditationType"
            checked={localState.isAccreditationType}
            onChange={handleCheckChanged}
            color="secondary"
            style={{ marginLeft: "12px" }}
            onClick={(e) => e.stopPropagation()}
          />
        }
        label="Accreditation Type"
        onClick={(e) => e.preventDefault()}
        className="checkbox-label"
      />
      {localState.isAccreditationType ? (
        <ReactSelect
          name="name"
          isClearable
          options={getDropdownOptions()}
          onChange={handleDropdownChange}
          value={
            localState.codeId
              ? {
                  value: localState.codeId,
                  label: codesData.find((code) => code.id === localState.codeId)
                    ?.codeName,
                }
              : null
          }
          styles={reactSelectStyles}
          placeholder="Select Variable Name"
          className="white_space_preserve_with_word_wrap"
        />
      ) : (
        <TextField
          name="name"
          label="Variable Name"
          variant="outlined"
          onChange={handleChange}
          value={localState.name}
          className="white_space_preserve_with_word_wrap"
        />
      )}

      {selectError && localState.isAccreditationType && (
        <p className="error">The Variable Name is required.</p>
      )}
      {localState.isAccreditationType && (
        <p className="notes pl-1 mb-1 mt-1">
          <b>Note:</b> If a positive value is created for a variable, a
          corresponding negative value should also be created. Using these +/-
          signs will impact the calculation of the Positive to Negative Ratio
          Calculation in the Accreditation Dashboard and report. Otherwise, the
          variable will be considered as a standalone value, and no positive or
          negative calculations will be shown for it.
        </p>
      )}
      <TextField
        name="displayName"
        label="Display Name"
        variant="outlined"
        onChange={handleChange}
        value={localState.displayName}
      />
      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="type">Variable Type</InputLabel>
        <Select
          native
          name="type"
          id="type"
          value={localState.isAccreditationType ? "number" : localState.type}
          onChange={handleSelectChange}
        >
          <option aria-label="None" value="" />
          {["boolean", "number", "string"].map((option) => {
            if (localState.isAccreditationType && option !== "number")
              return null;
            return (
              <option key={option} value={option}>
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </option>
            );
          })}
        </Select>
      </FormControl>

      <TextField
        name="desc"
        label="Description"
        variant="outlined"
        onChange={handleChange}
        value={localState.desc}
        multiline
        minRows={3}
        maxRows={5}
      />
      <TextField
        name="startVal"
        label="Starting Value"
        variant="outlined"
        onChange={handleChange}
        value={localState.startVal}
      />
      {(localState.isAccreditationType || localState.type == "number") && (
        <>
          <h5 className="mt-1">Use of Variable</h5>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="Use of Variable"
              defaultValue={`${localState.variableContext}`}
              value={`${localState.variableContext}`}
              name="variableContext"
              onChange={handleChange}
            >
              <FormControlLabel
                value="true"
                control={<Radio color="primary" />}
                label="Use Sum Value"
              />
              <FormControlLabel
                value="false"
                control={<Radio color="primary" />}
                label="Use Current Value"
              />
            </RadioGroup>
          </FormControl>
        </>
      )}
      <h5>Reporting</h5>

      <div className="visibility_setting_row">
        <FormControlLabel
          control={
            <Switch
              id="showOnReport"
              name="showOnReport"
              checked={localState.showOnReport}
              onChange={handleCheckChanged}
            />
          }
          label="Show On Report"
        />
        <FormControlLabel
          control={
            <Switch
              id="useAvg"
              name="useAvg"
              checked={localState.useAvg}
              onChange={handleCheckChanged}
            />
          }
          label="Calculate Avg"
        />
      </div>
      <div className="visibility_setting_row">
        <TextField
          name="minRange"
          label="Min-Range"
          variant="outlined"
          onChange={handleChange}
          value={localState.minRange === null ? "" : localState.minRange}
        />
        <TextField
          name="maxRange"
          label="Max-Range"
          variant="outlined"
          onChange={handleChange}
          value={localState.maxRange === null ? "" : localState.maxRange}
        />
        <TextField
          name="step"
          label="Step"
          variant="outlined"
          onChange={handleChange}
          value={localState.step === null ? "" : localState.step}
        />
      </div>
      <MuiThemeProvider theme={formLabelsTheme}>
        <form noValidate autoComplete="off">
          <h5>Slider Labels</h5>
          <div className="visibility_setting_row">
            <TextField
              required
              error={
                (sliderLabelText === "" || sliderLabelText === undefined) &&
                varErrorFlag &&
                true
              }
              name="label"
              id="sliderLabel"
              label="Label"
              variant="outlined"
              onChange={(e) => setSliderLabelText(e.target.value)}
              value={sliderLabelText}
              className="visibility_setting_row_item"
              inputProps={{ maxLength: 50 }}
              helperText={
                (sliderLabelText === "" || sliderLabelText === undefined) &&
                varErrorFlag &&
                "The Label field is required."
              }
            />
            <TextField
              required
              error={
                (sliderLabelValue === "" || sliderLabelValue === undefined) &&
                varErrorFlag &&
                true
              }
              name="value"
              id="sliderValue"
              label="Value"
              variant="outlined"
              onChange={(e) => setSliderLabelValue(e.target.value)}
              value={sliderLabelValue}
              className="visibility_setting_row_item"
              helperText={
                (sliderLabelValue === "" || sliderLabelValue === undefined) &&
                varErrorFlag &&
                "The Value field is required."
              }
            />
            <IconButton onClick={addSliderLabel}>
              <AddCircleIcon />
            </IconButton>
          </div>
        </form>
      </MuiThemeProvider>
      {getSliderLabels()}
      <Button
        name="saveVariableDetails"
        onClick={handleSave}
        variant="contained"
        color="primary"
      >
        Save Variable Details
      </Button>
    </div>
  );
};

export default EditVariable;
