import HttpService from "./HttpService";

const StandardsService = () => {
    return Object.freeze({
        getAllPortalStandards: async (token) => {
            return HttpService().getRequest(token, "/allStandards/getAll");
        },
    });
};

export default StandardsService;
