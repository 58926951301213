import TopNavForPublic from "../../generic/TopNavForPublic";
import { Rating } from "@material-ui/lab";
import React, { useState } from "react";
import Loader from "../../loader";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import { SB_URL } from "../../../Config";
import HttpService from "../../../services/HttpService";
import { ratingIcons } from "../../../utils/utils";

const initialState = {
  preQ1Text: null,
  preQ2Rating: 0,
  preQ3Rating: 0,
  preQ4Rating: 0,
  preQ5Rating: 0,
  preQ6Rating: 0,
};

const PreSimFeedback = (props) => {
  const [feedbackQuestions, setFeedbackQuestions] = useState({
    ...initialState,
  });

  const { linkId, linkAuditId } = useParams();

  const [customIcons] = useState(ratingIcons);
  const [isShowLoader, setShowLoader] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFeedbackQuestions((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleRatingChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    value = value == feedbackQuestions[name] ? 0 : value;
    setFeedbackQuestions((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const IconContainer = (props, name) => {
    const { value, ...other } = props;
    const classes = `icons-label ${
      feedbackQuestions[name] == value ? "active" : ""
    }`;
    return (
      <span {...other} className={classes}>
        {customIcons[value].icon}
      </span>
    );
  };

  const submitFeedback = (e) => {
    setShowLoader(true);
    const simFeedback = {
      ...feedbackQuestions,
      linkId: props.linkId || linkId,
      linkAuditId: props.linkAuditId || linkAuditId,
      feedbackUuid: uuidv4(),
      preSubmitted: true,
      simPlayUUID: props.simPlayUUID
    };

    HttpService()
      .postRequest(simFeedback, "/feedback")
      .then((response) => {
        if (response.status !== 200) {
          console.debug("Error: ", response.message);
        } else {
          if (linkId) {
            localStorage.setItem(
              "simPreFeedback",
              JSON.stringify(response.data)
            );
            window.location.href = `${SB_URL}/feedback`;
          } else {
            props.handleShowPreSimFeedbackPage(false);
            props.addSimPreFeedback(response.data);
          }
        }
        setShowLoader(false);
      })
      .catch((e) => {
        console.debug("Error while saving feedback", e.message);
      });
  };

  return (
    <>
      {isShowLoader && (
        <div className="overlay">
          <Loader />
        </div>
      )}
      <link href="/simFeedback.css" rel="stylesheet" />
      <TopNavForPublic />

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <section className="simulation-header p-0">
              <h3 className="title">Pre Simulation Feedback</h3>
            </section>
          </div>
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="form-group row">
                  <h5 className="col-sm-12 form-control-label">
                    Please describe your role in playing this simulation. For
                    example, student in an aspiring leader program, student in a
                    teacher candidate program, a principal preparation cohort
                    member, a professional development assignment, etc.?
                  </h5>
                  <div className="col-sm-12">
                    <textarea
                      name="preQ1Text"
                      rows={5}
                      maxLength={5000}
                      className="form-control"
                      value={feedbackQuestions.questionOne}
                      onChange={(event) => handleChange(event)}
                    />
                  </div>
                </div>

                <div>
                  <h5 className="">
                    I often get the chance to rehearse in advance the decisions
                    I must make at work.
                  </h5>

                  <div className="form-group row mt-md-5 mt-3">
                    <div className="col-sm-8 offset-sm-3 text-center">
                      <div className="row">
                        <div className="col-sm-12 col-md-9">
                          <Rating
                            className="large-rating-icons"
                            name="preQ2Rating"
                            defaultValue={0}
                            onChange={(event) => handleRatingChange(event)}
                            getLabelText={(value) => customIcons[value].label}
                            IconContainerComponent={(props) => {
                              return IconContainer(props, "preQ2Rating");
                            }}
                            size="large"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h5 className="">
                    I am confident in the decisions I make every day.
                  </h5>

                  <div className="form-group row mt-md-5 mt-3">
                    <div className="col-sm-8 offset-sm-3 text-center">
                      <div className="row">
                        <div className="col-sm-12 col-md-9">
                          <Rating
                            className="large-rating-icons"
                            name="preQ3Rating"
                            onChange={(event) => handleRatingChange(event)}
                            defaultValue={0}
                            getLabelText={(value) => customIcons[value].label}
                            IconContainerComponent={(props) => {
                              return IconContainer(props, "preQ3Rating");
                            }}
                            size="large"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h5 className="">
                    I often get time to reflect on my decisions.
                  </h5>

                  <div className="form-group row mt-md-5 mt-3">
                    <div className="col-sm-8 offset-sm-3 text-center">
                      <div className="row">
                        <div className="col-sm-12 col-md-9">
                          <Rating
                            className="large-rating-icons"
                            name="preQ4Rating"
                            onChange={(event) => handleRatingChange(event)}
                            defaultValue={0}
                            getLabelText={(value) => customIcons[value].label}
                            IconContainerComponent={(props) => {
                              return IconContainer(props, "preQ4Rating");
                            }}
                            size="large"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h5 className="">
                    My decisions have gotten more complicated in recent years.
                  </h5>

                  <div className="form-group row mt-md-5 mt-3">
                    <div className="col-sm-8 offset-sm-3 text-center">
                      <div className="row">
                        <div className="col-sm-12 col-md-9">
                          <Rating
                            className="large-rating-icons"
                            name="preQ5Rating"
                            onChange={(event) => handleRatingChange(event)}
                            defaultValue={0}
                            getLabelText={(value) => customIcons[value].label}
                            IconContainerComponent={(props) => {
                              return IconContainer(props, "preQ5Rating");
                            }}
                            size="large"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h5 className="">
                    I often feel stressed about the decisions I must make at
                    work.
                  </h5>

                  <div className="form-group row mt-md-5 mt-3">
                    <div className="col-sm-8 offset-sm-3 text-center">
                      <div className="row">
                        <div className="col-sm-12 col-md-9">
                          <Rating
                            className="large-rating-icons"
                            name="preQ6Rating"
                            onChange={(event) => handleRatingChange(event)}
                            defaultValue={0}
                            getLabelText={(value) => customIcons[value].label}
                            IconContainerComponent={(props) => {
                              return IconContainer(props, "preQ6Rating");
                            }}
                            size="large"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-12 text-center">
                    <button
                      type="button"
                      className="btn white float-right"
                      onClick={submitFeedback}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreSimFeedback;
