import { SIM_BUILDER_API_END_POINT } from "../Config";
import RequestService, { HttpMethods } from "../utils/requestService";

const HttpService = () => {
    return Object.freeze({
        postRequest: async (payload, endPoint) => {
            const options = RequestService.makeRequest(HttpMethods.POST);
            options["body"] = JSON.stringify(payload);

            const result = await fetch(SIM_BUILDER_API_END_POINT + endPoint, options);
            const response = await result.json();

            return response;
        },
        postRequestWithAuthentication: async (payload, endPoint, token) => {
            const options = RequestService.makeRequest(HttpMethods.POST, token);
            options["body"] = JSON.stringify(payload);

            const result = await fetch(SIM_BUILDER_API_END_POINT + endPoint, options);
            const response = await result.json();

            return response;
        },
        getRequest: async (token, path) => {
            const options = RequestService.makeRequest(HttpMethods.GET, token);
            const result = await fetch(SIM_BUILDER_API_END_POINT + path, options);
            const response = await result.json();
            return response;
        },
        getRequestById: async (token, path, id) => {
            const options = RequestService.makeRequest(HttpMethods.GET, token);
            const result = await fetch(
                SIM_BUILDER_API_END_POINT + path + id,
                options
            );
            const response = await result.json();
            return response;
        },
        deleteRequestById: async (token, path, id) => {
            const options = RequestService.makeRequest(HttpMethods.DELETE, token);
            const result = await fetch(
                SIM_BUILDER_API_END_POINT + path + "/" + id,
                options
            );
            const response = await result.json();
            return response;
        },
    });
};

export default HttpService;
