import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const InternalSimAlertDialog = ({
  handleYes,
  handleNo,
  message,
  open,
  showDialogActions,
}) => {
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <strong className="MuiButton-textSecondary">Alert!</strong>
      </DialogTitle>
      <DialogContent>
        <h4>{message}</h4>
      </DialogContent>
      {showDialogActions ? (
        <DialogActions>
          <Button onClick={handleNo} color="primary">
            No
          </Button>
          <Button onClick={handleYes} color="secondary">
            Yes
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button onClick={handleNo} color="primary">
            Ok
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default InternalSimAlertDialog;
