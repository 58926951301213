import { IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Button, Tooltip } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import StaticTable from "../generic/StaticTablePagination";
import EditIcon from "@material-ui/icons/Edit";
import { Delete } from "@material-ui/icons";
import LoaderComponent from "../loader";
import StandardsService from "../../services/StandardsService";
import HttpService from "../../services/HttpService";
import { getStandardLabels } from "../../utils/utils";
import AlertDialog from "../generic/Alerts";
import InternalSimAlertDialog from "../generic/InternalSimAlertDialog";

const Codes = () => {
  const history = useHistory();
  const { token } = useContext(UserContext);
  const [codesData, setCodesData] = useState([]);
  const [standards, setStandards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertProps, setAlertProps] = useState({ msg: "", type: "" });
  const [openAlert, setOpenAlert] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedCode, setSelectedCode] = useState({});
  const [showDialogActions, setShowDialogActions] = useState(false);
  const [dbResponse, setDbResponse] = useState();

  useEffect(() => {
    setLoading(true);
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    StandardsService()
      .getAllPortalStandards(token)
      .then((response) => {
        setStandards(
          response?.data?.standards.map((standard) => {
            return { ...standard, value: standard.uuid };
          })
        );
      })
      .catch((e) => {
        console.debug("Error Occurred", e.message);
      });

    HttpService()
      .getRequest(token, "/codes")
      .then((response) => {
        setCodesData(response.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.debug("Error occurred while getting the codes", e.message);
      });
  };

  const columns = [
    {
      dataField: "codeName",
      text: "Code Name",
      sort: true,
      classes: "content-body title",
      headerClasses: "table_header_color",
      align: "left",
      headerStyle: { minWidth: 320 },
      formatter: (cell, record) => (
        <p className="white_space_preserve_with_word_wrap">{record.codeName}</p>
      ),
    },
    {
      dataField: "standards",
      text: "Standards",
      classes: "content-body title",
      headerClasses: "table_header_color cursor-pointer",
      align: "left",
      headerStyle: { minWidth: 500 },
      formatter: (cell, record) => {
        const standardLabels = getStandardLabels(
          record.codeStandards,
          standards
        );
        return <p>{standardLabels.join(", ")}</p>;
      },
    },
    {
      dataField: "action",
      text: "Actions",
      classes: "content-body",
      headerClasses: "text-center",
      align: "center",
      headerStyle: { width: 170 },
      formatter: (cell, code) => {
        return (
          <>
            <Tooltip title="Edit Code" placement="top">
              <Link to={`/editCode/${code.id}`}>
                <EditIcon color="primary" />
              </Link>
            </Tooltip>
            <Tooltip title="Delete Code" placement="top">
              <IconButton onClick={() => handleDelete(code)}>
                <Delete color="secondary" />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const handleCodeDelete = async () => {
    setOpenDeleteModal(false);
    setLoading(true);
    const response = await HttpService().deleteRequestById(
      token,
      "/codes",
      selectedCode.id
    );
    if (response?.status == 200) {
      console.debug("Code deleted successfully!");
      setLoading(false);
      setAlertProps({ msg: "Code deleted successfully!", type: "success" });
    } else {
      setLoading(false);
      console.debug("Error deleting the ");
    }
    setOpenAlert(true);
    loadData();
  };

  const handleDelete = async (record) => {
    setSelectedCode(record);
    setLoading(true);
    const response = await HttpService().getRequestById(
      token,
      "/codes/getAllSimForCode/",
      record?.id
    );
    if (response?.status == 200) {
      // No dependency of code in sims
      if (response?.data.length == 0) {
        setLoading(false);
        console.debug("Code deleted successfully!");
        setShowDialogActions(true);
        setOpenDeleteModal(true);
      } else {
        setLoading(false);
        console.error("Code in use, please remove dependency from Variables!");
        setDbResponse(response?.data);
        setShowDialogActions(false);
        setOpenDeleteModal(true);
      }
    } else {
      console.debug("Error deleting the ");
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="h4 color-blue">Accreditation Codes</h3>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push("/addCode");
            }}
          >
            Add Code
          </Button>
        </div>
      </div>

      <div className="card-body">
        <div className="overflow-auto">
          <StaticTable
            data={codesData}
            columns={columns}
            id="codeId"
            select={false}
          />
        </div>
      </div>
      {loading && (
        <div className="overlay">
          <LoaderComponent />
        </div>
      )}
      {openAlert && (
        <AlertDialog
          {...alertProps}
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
        />
      )}

      {openDeleteModal && (
        <InternalSimAlertDialog
          open={openDeleteModal}
          handleNo={() => setOpenDeleteModal(false)}
          message={
            showDialogActions ? (
              <p className="dialog-content-font">
                Are you sure you want to delete{" "}
                <span className="MuiButton-textSecondary font-weight">
                  {selectedCode.codeName} ?
                </span>
                <br />
                <span className="MuiButton-textSecondary font-weight">
                  All data and scores related to this code will be deleted from
                  the system and corresponding reports.
                </span>
              </p>
            ) : (
              <p className="dialog-content-font">
                The code{" "}
                <span className="MuiButton-textSecondary font-weight">
                  {selectedCode.codeName}
                </span>
                <span>
                  {" "}
                  in currently use by the following simulations:{" "}
                  <span className="response-grey">
                    {dbResponse
                      ?.map((item, index) => (
                        <strong key={index}>{item.trim()}</strong>
                      ))
                      .reduce((prev, curr) => [prev, ", ", curr])}
                  </span>
                </span>
                <br />
                <span className="MuiButton-textSecondary font-weight">
                  Please remove these dependencies before attempting to delete
                  the code.
                </span>
              </p>
            )
          }
          handleYes={handleCodeDelete}
          showDialogActions={showDialogActions}
        />
      )}
    </div>
  );
};

export default Codes;
