import React from "react";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import PeopleIcon from "@material-ui/icons/People";
import ArchiveIcon from "@material-ui/icons/Archive";
import { Assignment } from "@material-ui/icons";

const LeftNavMenu = (props) => {
  const linkClass = props.isExpand ? "a_open" : "a_close";
  const loc = window.location;
  const pathName = loc.pathname;

  return (
    <>
      {props.markup}
      <Link
        to="/dashboard"
        className={`${linkClass} ${pathName === "/dashboard" ? "active" : ""}`}
      >
        <HomeIcon className="margin-right" />
        Home
      </Link>
      <Link
        to="/library"
        className={`${linkClass} ${pathName === "/library" ? "active" : ""}`}
      >
        <VideoLibraryIcon className="margin-right" />
        Simulation Library
      </Link>
      <Link
        to="/simIdeas"
        className={`${linkClass} ${pathName === "/simIdeas" ? "active" : ""}`}
      >
        <EmojiObjectsIcon className="margin-right" />
        Simulation Ideas
      </Link>
      <Link
        to="/internalSimulations"
        className={`${linkClass} ${pathName === "/internalSimulations" ? "active" : ""
          }`}
      >
        <ArchiveIcon className="margin-right" />
        Internal Simulations
      </Link>
      <Link
        to="/codes"
        className={`${linkClass} ${pathName === "/codes" ? "active" : ""
          }`}
      >
        <Assignment className="margin-right" />
        Accreditation Codes
      </Link>
      <Link
        to="/users"
        className={`${linkClass} ${pathName === "/users" ? "active" : ""}`}
      >
        <PeopleIcon className="margin-right" />
        Users
      </Link>
    </>
  );
};

export default LeftNavMenu;
