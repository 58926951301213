/**
 * This will check for +/- at the end
 */
export const regexForPlusMinusAtEnd = /\s*[+-]$/;

/**
 *  Trimming spaces before the + or - sign and ensure only one space before the sign if there were multiple
 */
export const trailingOperatorDetection = /\s+([+-])$/;

/**
 * This method will validate code Name
 * @param {*} input
 * @returns
 */
export const validateAndFormatCodes = (input) => {
  // Updated regex to allow additional special characters
  const regex = /[^+-][+-]$/;

  // Test the input
  const match = input.match(regex);

  if (match) {
    return input;
  }
  return false;
};

/**
 * Function to check if the code name already exists and is active
 * @param {*} codeName
 * @param {*} codesData
 * @returns
 */
export const checkExistingCode = (codeName, codesData) => {
  return codesData.find(
    (code) =>
      code.codeName.trim().toLowerCase() === codeName.trim().toLowerCase() &&
      code.status === true
  );
};
