import { Button, Tooltip, IconButton } from "@material-ui/core";
import DateUtil from "../../utils/dateUtil";
import { Delete, Visibility } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { useEffect, useContext, useState } from "react";
import InternalSimService from "../../services/InternalSimService";
import { UserContext } from "../../context/UserContext";
import AlertDialog from "../generic/Alerts";
import LoaderComponent from "../loader";
import { LibraryContext } from "../../context/LibraryContext";
import InternalSimAlertDialog from "../generic/InternalSimAlertDialog";
import { INTERNAL_SIM_ALERTS } from "../../utils/AlertDialogMessages";
import ArchiveIcon from "@material-ui/icons/Archive";
import StaticTable from "../generic/StaticTablePagination";
import { textWrapForTableLongDescription } from "../../utils/utils";

const InternalSimulations = () => {
  const history = useHistory();
  const { token } = useContext(UserContext);
  const [internalSims, setInternalSims] = useState([]);
  const [alertProps, setAlertProps] = useState({ msg: "", type: "" });
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedSim, setSelectedSim] = useState({});
  const libContext = useContext(LibraryContext);

  useEffect(() => {
    setLoading(true);
    InternalSimService()
      .getAllInternalSims(token)
      .then((response) => {
        setInternalSims(response.data);
        setLoading(false);
      })
      .catch((e) => {
        console.debug(
          INTERNAL_SIM_ALERTS.errorGetInternalSimsMessage.msg,
          e.message
        );
        setOpenAlert(true);
        setAlertProps({
          msg: INTERNAL_SIM_ALERTS.errorGetInternalSimsMessage.msg,
          type: INTERNAL_SIM_ALERTS.errorGetInternalSimsMessage.type,
        });
        setLoading(false);
      });
  }, []);

  const handleViewSim = (selected) => {
    history.push(`/viewInternalSim/${selected.simId}`);
  };

  const exportSim = async (simId) => {
    setLoading(true);
    const response = await InternalSimService().exportInternalSim(
      { simId },
      token
    );
    if (response.status === 200) {
      libContext.addSimToLibrary(response.data);
      setAlertProps({ msg: response.message, type: "success" });
    } else {
      console.debug(INTERNAL_SIM_ALERTS.errorExportInternalSimMessage.msg);
      setAlertProps({
        msg: INTERNAL_SIM_ALERTS.errorExportInternalSimMessage.msg,
        type: INTERNAL_SIM_ALERTS.errorExportInternalSimMessage.type,
      });
    }
    setLoading(false);
    setOpenAlert(true);
  };

  const deleteSim = async () => {
    setOpenDeleteModal(false);
    setLoading(true);
    const response = await InternalSimService().deleteInternalSimById(
      selectedSim.simId,
      token
    );
    if (response.status === 200) {
      setAlertProps({ msg: response.message, type: "success" });
      setInternalSims(response.data);
    } else {
      console.debug(INTERNAL_SIM_ALERTS.errorDeleteInternalSimMessage.msg);
      setAlertProps({
        msg: INTERNAL_SIM_ALERTS.errorDeleteInternalSimMessage.msg,
        type: INTERNAL_SIM_ALERTS.errorDeleteInternalSimMessage.type,
      });
    }
    setLoading(false);
    setOpenAlert(true);
  };

  const handleDelete = (record) => {
    setSelectedSim(record);
    setOpenDeleteModal(true);
  };

  const columns = [
    {
      dataField: "title",
      text: "Sim Name",
      sort: true,
      classes: "content-body title",
      headerClasses: "table_header_color",
      align: "left",
      headerStyle: { minWidth: 300 },
      formatter: (cell, record) => (
        <p style={textWrapForTableLongDescription}>{record.title}</p>
      ),
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      classes: "content-body title",
      headerClasses: "table_header_color",
      align: "left",
      headerStyle: { minWidth: 500 },
      formatter: (cell, record) => {
        return (
          <p style={textWrapForTableLongDescription}>{record.description}</p>
        );
      },
    },
    {
      dataField: "createdAt",
      text: "Created Date",
      sort: true,
      classes: "content-body",
      headerClasses: "table_header_color text-center",
      align: "center",
      headerStyle: { minWidth: 170 },
      formatter: (cell, record) => {
        return DateUtil.formatDate(new Date(record.createdAt), "dd/mm/yyyy");
      },
    },
    {
      dataField: "action",
      text: "Actions",
      classes: "content-body",
      headerClasses: "text-center",
      align: "center",
      headerStyle: { width: 170 },
      formatter: (cell, record) => {
        return (
          <>
            <Tooltip title="View Simulation" placement="top">
              <IconButton onClick={() => handleViewSim(record)}>
                <Visibility />
              </IconButton>
            </Tooltip>
            <Tooltip title="Export Simulation" placement="top">
              <IconButton onClick={() => exportSim(record.simId)}>
                <ArchiveIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Simulation" placement="top">
              <IconButton onClick={() => handleDelete(record)}>
                <Delete color="secondary" />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <div className="card">
      <div className="card-header">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="h4 color-blue">Internal Simulations</h3>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push("/addInternalSim");
            }}
          >
            Import Internal Simulation
          </Button>
        </div>
      </div>

      <div className="card-body">
        <div className="overflow-auto">
          <StaticTable
            data={internalSims}
            columns={columns}
            id="simId"
            select={false}
          />
        </div>
      </div>
      {openAlert && (
        <AlertDialog
          {...alertProps}
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
        />
      )}
      {loading && (
        <div className="overlay">
          <LoaderComponent />
        </div>
      )}
      {openDeleteModal && (
        <InternalSimAlertDialog
          open={openDeleteModal}
          handleNo={() => setOpenDeleteModal(false)}
          message={
            <h4>
              Are you sure you want to delete{" "}
              <span className="MuiButton-textSecondary">
                {selectedSim.title}
              </span>
              ?
            </h4>
          }
          handleYes={deleteSim}
          showDialogActions={true}
        />
      )}
    </div>
  );
};

export default InternalSimulations;
