import HttpService from "./HttpService";

const CodeScoreService = () => {
  return Object.freeze({
    saveCodeScore: async (dataResult) => {
      return HttpService().postRequest(dataResult, "/codeScore/save");
    },
    // will remove in the next sprint
    saveSessionCodeScore: async (dataResult) => {
      return HttpService().postRequest(dataResult, "/codeScore/saveCode");
    },
  });
};

export default CodeScoreService;
